import React from "react";
import styled from "styled-components";

const StatusInfo = (props) => {
  const httpError = props.httpError;
  let errorMessage = "";

  if (props.httpError !== "") {
    errorMessage = httpError;
  }

  if (!props.isReadyForOrder) {
    errorMessage = "未能點餐,店舖離線或QRCode已過期！";
  }

  if (props.isReadyForOrder && errorMessage === "") {
    return (
      <Container>
        <BranchName>{process.env.REACT_APP_BRANCH_NAME}</BranchName>
        <TextTable>枱號</TextTable>
        <TableNumber> {props.tableNumber} </TableNumber>
      </Container>
    );
  } else {
    return (
      <Container>
        <InvalidOrder>{errorMessage}</InvalidOrder>
      </Container>
    );
  }
};

export default StatusInfo;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  margin-top: 50px;
`;

const BranchName = styled.div`
  font-size: 25px;
`;

const TextTable = styled.div`
  flex: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  padding-top: 60px;
  font-size: 22px;
`;
const TableNumber = styled.div`
  flex: 1;
  font-weight: bold;
  font-size: 80px;
  text-align: center;
  padding: 20px;
`;

const InvalidOrder = styled.div`
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
`;
