import React from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";

const AddItemForm = (props) => {
  const submitHandler = (event) => {
    event.preventDefault();
    props.onAddToCart();
  };
  return (
    <form onSubmit={submitHandler}>
      <ConfirmButton variant="contained" type="submit">
        加入
      </ConfirmButton>
    </form>
  );
};

export default AddItemForm;

const ConfirmButton = styled(Button)`
  width: 100%;
`;
