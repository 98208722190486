import React from "react";
import { useState } from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";

const Specials = (props) => {
  const specialCode = props.specialCode;
  //const maxCount = props.specialMax;
  const onUpdatePrice = props.onUpdatePrice;
  const onUpdateSpecial = props.onUpdateSpecial;
  const menuSpecials = JSON.parse(localStorage.getItem("menuSpecials"));
  let specialContent = menuSpecials.find((item) => {
    return item.chgno === specialCode;
  });

  const [selectedSpecial, setSelectedSpecial] = useState([]);

  const specialButtonClickHandler = (
    pkey,
    name,
    price,
    itemname,
    itemlabel,
    multi
  ) => {
    const existingSpecialItemIndex = selectedSpecial.findIndex(
      (e) => e.pkey === pkey
    );
    if (existingSpecialItemIndex < 0) {
      //new selection
      if (selectedSpecial.length < 6) {
        const updatedSpecial = selectedSpecial.concat({
          pkey: pkey,
          name: name,
          price: price,
          itemname: itemname,
          itemlabel: itemlabel,
          multi: multi,
        });
        setSelectedSpecial(updatedSpecial);
        onUpdatePrice(price);
        onUpdateSpecial(updatedSpecial);
      }
    } else {
      //already selected
      //const existingItem = selectedSpecial[existingSpecialItemIndex];
      let updatedItems;

      updatedItems = selectedSpecial.filter((e) => e.pkey !== pkey);
      setSelectedSpecial(updatedItems);
      onUpdatePrice(-price);
      onUpdateSpecial(updatedItems);
    }
  };
  return (
    <SpeicalContent>
      {specialCode !== "00" &&
        specialContent["Items"].length > 0 &&
        specialContent["Items"].map((obj, index) => (
          <SpecialItem
            key={obj.pkey}
            variant={
              selectedSpecial.find((e) => e.pkey === obj.pkey)
                ? "contained"
                : "outlined"
            }
            color={
              selectedSpecial.find((e) => e.pkey === obj.pkey)
                ? "success"
                : "primary"
            }
            onClick={specialButtonClickHandler.bind(
              null,
              obj.pkey,
              obj.weborder_name1,
              parseFloat(obj.chgprice),
              obj.itemname,
              obj.itemlabel,
              obj.multi
            )}
            sx={{ margin: "2px" }}
          >
            <ButtonText>{obj.weborder_name1}</ButtonText>
            <ButtonPrice>
              {parseFloat(obj.chgprice) > 0 && `$${parseFloat(obj.chgprice)}`}
            </ButtonPrice>
          </SpecialItem>
        ))}
      {specialCode === "00" && <div> 沒有資料 / Data Missing </div>}
    </SpeicalContent>
  );
};

export default Specials;

const SpeicalContent = styled.div`
  width: 100%;
  height: 58vh;
  overflow: scroll;
`;
const SpecialItem = styled(Button)`
  width: 48%;
  height: 70px;
  display: flex;
  padding: 0px;
`;

const ButtonText = styled.div`
  flex: 2;
  text-align: left;
`;
const ButtonPrice = styled.div`
  flex: 1;
  text-align: right;
  color: red;
`;
