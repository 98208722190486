import React from "react";

const CartShowItemName = (props) => {
  const item = props.item;
  let name = item.name;

  item.options.map((i) => {
    name = name + i.name;
  });
  return <div>{name}</div>;
};

export default CartShowItemName;
