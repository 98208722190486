import React, { useState } from "react";
import styled from "styled-components";
import CategoryTabs from "./CategoryTabs";
import CategoryImageList from "./CategoryImageList";

const Category = () => {
  const mCategories = JSON.parse(localStorage.getItem("menuItems"));
  //ToDo: filter start time and end time

  const [selectedCategory, setSelectedCategory] = useState(0);

  const selectedCategoryHandler = (categoryPkey) => {
    setSelectedCategory(categoryPkey);
  };

  if (!selectedCategory) {
    return (
      <CategoryItems>
        <CategoryImageList
          onSelectedCategory={selectedCategoryHandler}
          categories={mCategories}
        />
      </CategoryItems>
    );
  } else {
    return (
      <CategoryTabs
        selectedCategoryPkey={selectedCategory}
        onClickBackButton={selectedCategoryHandler}
      />
    );
  }
};

export default Category;

const CategoryItems = styled.div`
  display: flex;
  height: 85vh;
  flex-wrap: wrap;
  overflow: scroll;
  justify-content: space-around;
`;
