import React from "react";
import styled from "styled-components";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";

const CategoryImageList = (props) => {
  const categories = props.categories;

  const itemData = categories.map((item) => {
    const c = {
      img: "/img/" + item.weborder_image,
      title: item.weborder_name1,
      pkey: item.pkey,
    };
    return c;
  });

  const categoryClickHandler = (event) => {
    props.onSelectedCategory(event.currentTarget.value.toString());
  };

  return (
    <Container>
      <SytledImageList rowHeight={180}>
        {itemData.map((item) => (
          <StyledImageListItem
            key={item.pkey}
            value={item.pkey}
            onClick={categoryClickHandler}
          >
            <img src={item.img} alt={item.title} />
            <StyledImageListItemBar
              title={item.title}
              subtitle={<span>{item.author}</span>}
            />
          </StyledImageListItem>
        ))}
      </SytledImageList>
    </Container>
  );
};

export default CategoryImageList;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: "hidden";
`;

const SytledImageList = styled(ImageList)`
  width: 500;
  height: 85vh;
  padding: 10px;
  align-content: flex-start;
`;

const StyledImageListItem = styled(ImageListItem)`
  opacity: 80%;
`;

const StyledImageListItemBar = styled(ImageListItemBar)`
  height: 40px;
`;
