import React, { useState, useEffect } from "react";
import styled from "styled-components";
import OptionContent from "./OptionContent";
import Specials from "./Specials";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import AddItemForm from "./AddItemForm";

const Options = (props) => {
  const optionCode = props.optionCode;
  const maxCount = props.optionCount;
  const specialCode = props.specialCode;
  const specialMax = props.specialMax;
  const onUpdatePrice = props.onUpdatePrice;
  const onUpdateSpecial = props.onUpdateSpecial;
  const onUpdateOptions = props.onUpdateOptions;
  const onAddCart = props.addCart;

  const arrayOptCode = optionCode.match(/.{1,2}/g);
  const arrayMaxCnt = maxCount.match(/.{1,2}/g);
  const menuOptions = JSON.parse(localStorage.getItem("menuOptions"));

  const [step, setStep] = useState(0);
  const [cnt, setCnt] = useState(0);
  const [nextDisabled, setNextDisabled] = useState(true);
  const [selectedOptions, setSelectedOptions] = useState([]);
  let options = [];

  arrayOptCode.forEach((code, index, array) => {
    let optContent = menuOptions.find((item) => {
      return item.foodcode === code;
    });
    optContent["count"] = parseInt(arrayMaxCnt[index]);
    options.push(optContent);
  });

  useEffect(() => {
    if (options[step].weborder_optional === "Y") setNextDisabled(false);
  }, []); //run first time

  const optionClickHandler = (
    pkey,
    name,
    price,
    split,
    foodcode,
    itemlabel,
    itemname,
    p
  ) => {
    let count = 0;

    const existingOptionItemIndex = selectedOptions.findIndex(
      (e) => e.pkey === pkey
    );
    if (existingOptionItemIndex < 0) {
      if (selectedOptions.length < parseInt(arrayMaxCnt[step])) {
        const updatedOptions = selectedOptions.concat({
          pkey: pkey,
          name: name,
          price: price,
          split: split,
          foodcode: foodcode,
          itemname: itemname,
          itemlabel: itemlabel,
          p: p,
        });
        setSelectedOptions(updatedOptions);
        count = cnt + 1;
        setCnt(count);
        onUpdatePrice(+price);
      } else {
        return;
      }
    } else {
      //Item exists
      //const existingItem = selectedOptions[existingOptionItemIndex];
      let updatedItems;

      updatedItems = selectedOptions.filter((e) => e.pkey !== pkey);
      setSelectedOptions(updatedItems);
      count = cnt - 1;
      setCnt(count);
      onUpdatePrice(-price);
    }

    if (options[step].weborder_optional === "Y") {
      setNextDisabled(false);
    } else {
      if (count === parseInt(arrayMaxCnt[step])) setNextDisabled(false);
      else setNextDisabled(true);
    }
  };

  //let isShowSpecial = false;

  const nextStepClickHandler = (event) => {
    let s = step + 1;
    setStep(s);
    setCnt(0);
    setNextDisabled(true);

    if (s < options.length) {
      //check whether optional , if yes enabl button
      if (options[s].weborder_optional === "Y") setNextDisabled(false);
    }
    onUpdateOptions(selectedOptions);
    setSelectedOptions([]);
    //if (s > options.length) isShowSpecial = true;
  };

  return (
    <>
      {step < options.length && (
        <>
          <OptionSection>
            <Divider>請選擇所需項目</Divider>
            <OptionTitle key={options[step].pkey}>
              <OptionCategory> {options[step].weborder_name1} </OptionCategory>
              <OptionCount>請選擇 {options[step].count} 項</OptionCount>
            </OptionTitle>
            <OptionWrapper>
              <OptionContent
                optionItems={options[step].Items}
                onOptionClick={optionClickHandler}
                selectedOptions={selectedOptions}
              />
            </OptionWrapper>
          </OptionSection>
          <Bottom>
            <ConfirmButton
              variant="contained"
              disabled={nextDisabled}
              onClick={nextStepClickHandler}
            >
              下一步
            </ConfirmButton>
          </Bottom>
        </>
      )}
      {step >= options.length && (
        <>
          <SpecialSection>
            <Divider>請選擇特別要求(最多{specialMax}項)</Divider>
            <Specials
              specialCode={specialCode}
              specialMax={specialMax}
              onUpdatePrice={onUpdatePrice}
              onUpdateSpecial={onUpdateSpecial}
            />
          </SpecialSection>
          <Bottom>
            <AddItemForm onAddToCart={onAddCart} />
          </Bottom>
        </>
      )}
    </>
  );
};

export default Options;

const Bottom = styled.div`
  width: 100%;
  height: 5vh;
  text-align: center;
  margin-top: auto;
  align-self: flex-end;
`;
const ConfirmButton = styled(Button)`
  width: 100%;
`;
const OptionWrapper = styled.div`
  overflow: scroll;
`;

const OptionSection = styled.div`
  width: 100%;
`;
const SpecialSection = styled.div`
  width: 100%;
`;

const OptionTitle = styled.div`
  background-color: #c4c4ce;
  width: 100%;
  height: 30px;
  display: flex;
  padding: 0px;
`;
const OptionCategory = styled.div`
  flex: 1;
  padding: 3px;
`;

const OptionCount = styled.div`
  flex: 1;
  text-align: right;
  padding: 3px;
`;
