import React from "react";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Modal from "../UI/Modal";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import LoadingSpinner from "../UI/LoadingSpinner";

const OrderedItems = (props) => {
  const params = useParams();
  const token = params.token;
  const header = {
    "Content-Type": "application/json",
    token: "Bear " + token,
  };
  const [httpError, setHttpError] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [hasItems, setHasItems] = useState(false);

  const [Items, setItems] = useState([]);

  useEffect(() => {
    getOrderedItemsHandler();
  }, []);

  const isFetchingOrderedModalContent = (
    <ModalInfoContent>
      <Submitting>
        <LoadingSpinner />
      </Submitting>
    </ModalInfoContent>
  );

  const getOrderedItemsHandler = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/getOrdered",
        {
          method: "POST",
          headers: header,
          body: JSON.stringify({
            data: token,
          }),
        }
      );
      if (!response.ok) throw new Error("資料讀取失敗，請重試！");

      const data = await response.json().catch((error) => {
        setHttpError(error.message);
      });

      if (data) {
        setItems(data.data);
        setIsFetching(false);
        setHasItems(data.data.length > 0);
      }
    } catch (e) {
      setHttpError(e.message);
    }
  };

  return (
    <>
      <Modal onClose={props.onClose}>
        <OrderedContainer>
          <OrderedNav>
            <OrderedNavRight>
              <Button variant="outlined" onClick={props.onClose}>
                關閉
              </Button>
            </OrderedNavRight>
          </OrderedNav>
          <Divider>已落單項目</Divider>
          <OrderedContent>
            {hasItems &&
              Items.map((i) => (
                <OrderedItem key={i.pkey}>
                  <OrderedItemName>{i.foodname}</OrderedItemName>
                  <OrderedItemQuantity>{i.quantity}</OrderedItemQuantity>
                  <OrderedItemAmount>{i.totalamt}</OrderedItemAmount>
                </OrderedItem>
              ))}
          </OrderedContent>
        </OrderedContainer>
      </Modal>
    </>
  );
};

export default OrderedItems;

const OrderedContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const OrderedNav = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
`;

const OrderedContent = styled.div`
  width: 100%;
  height: 60vh;
  overflow: scroll;
`;

const OrderedNavRight = styled.div`
  flex: 1;
  text-align: right;
`;

const OrderedItemList = styled.div``;

const OrderedItem = styled.li`
  display: flex;
`;

const Submitting = styled.div`
  text-align: center;
`;

const ModalInfoContent = styled.div`
  width: 100%;
  text-align: center;
  height: 45vh;
  padding-top: 100px;
`;

const OrderedItemName = styled.div`
  flex: 5;
  text-align: left;
  padding-top: 5px;
  min-height: 40px;
`;

const OrderedItemQuantity = styled.div`
  text-align: center;
  flex: 1;
  padding-top: 7px;
`;

const OrderedItemAmount = styled.div`
  flex: 2;
  text-align: right;
  justify-content: flex-end;
  padding-top: 5px;
`;
