import React from "react";
import { useState, useEffect } from "react";
import styled from "styled-components";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useParams } from "react-router-dom";
import OrderContext from "../store/OrderContext";
import ReadyForOrder from "../components/ReadyForOrder";

const Home = () => {
  const params = useParams();
  const token = params.token;
  const header = {
    "Content-Type": "application/json",
    token: "Bear " + token,
  };

  const [isShopOnline, setIsShopOnline] = useState(false);
  const [shopId, setShopId] = useState("");
  const [tableNumber, setTableNumber] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [httpError, setHttpError] = useState("");

  useEffect(() => {
    const fetchShopStatusHandler = async () => {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/getShopStatus"
      );
      if (!response.ok) throw new Error("Cannot Load Shop Status");

      const data = await response.json();
      const shopStatus = data.status;
      const r = shopStatus === "online" ? true : false;
      setIsShopOnline(r);
    };

    const isTokenValid = async () => {
      const response = await fetch(process.env.REACT_APP_URL + "/auth", {
        method: "POST",
        headers: header,
      });
      if (!response.ok)
        throw new Error("QRCode 驗証失敗或QRCode已過期，請聯絡店舖職員重發");

      const data = await response.json().catch((error) => {
        setIsLoading(false);
        setHttpError(error.message);
      });
      if (data) {
        setShopId(data.uid);
        setTableNumber(data.tbl);
        setSerialNumber(data.sn);

        fetchShopStatusHandler().catch((error) => {
          setIsLoading(false);
          setHttpError(error.message);
        });
        fetchShopMenuHandler().catch((error) => {
          setIsLoading(false);
          setHttpError(error.message);
        });
      }
    };

    const fetchShopMenuHandler = async () => {
      const responseItems = await fetch(
        process.env.REACT_APP_API_URL + "/getMenu/items.json",
        {
          headers: header,
        }
      );
      if (!responseItems.ok)
        throw new Error("未能成功下載餐牌資料 [ERROR 101]");

      const dataCategories = await responseItems.json();
      localStorage.removeItem("menuItems");
      localStorage.setItem("menuItems", JSON.stringify(dataCategories));

      const responseOptions = await fetch(
        process.env.REACT_APP_API_URL + "/getMenu/options.json",
        {
          headers: header,
        }
      );
      if (!responseOptions.ok)
        throw new Error("未能成功下載餐牌資料 [ERROR 102]");
      const dataOptions = await responseOptions.json();
      localStorage.removeItem("menuOptions");
      localStorage.setItem("menuOptions", JSON.stringify(dataOptions));

      const responseSpecials = await fetch(
        process.env.REACT_APP_API_URL + "/getMenu/specials.json",
        {
          headers: header,
        }
      );
      if (!responseSpecials.ok)
        throw new Error("未能成功下載餐牌資料 [ERROR 103]");
      const dataSpecials = await responseSpecials.json();
      localStorage.removeItem("menuSpecials");
      localStorage.setItem("menuSpecials", JSON.stringify(dataSpecials));
    };
    setIsLoading(false);
    isTokenValid().catch((error) => {
      setIsLoading(false);
      setHttpError(error.message);
    });
  }, [token]);

  if (isLoading) {
    return <Loading> 載入中 ... </Loading>;
  }

  const isReadyForOrder =
    isShopOnline && tableNumber !== undefined ? true : false;

  return (
    <OrderContext.Provider
      value={{
        token: token,
        tableNumber: tableNumber,
        serialNumber: serialNumber,
        shopId: shopId,
      }}
    >
      <Wrapper>
        <Container>
          <Navbar isShopOnline={isShopOnline} />

          <ReadyForOrder
            isReadyForOrder={isReadyForOrder}
            httpError={httpError}
            tableNumber={tableNumber}
          />
          <Footer />
        </Container>
      </Wrapper>
    </OrderContext.Provider>
  );
};

export default Home;

const HttpError = styled.div``;

const Loading = styled.div``;

const Container = styled.div`
  width: 100vw;
  height: 100%;
  background: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    url(${process.env.PUBLIC_URL + "/img/bg_shop.jpg"}) center;
  background-size: cover;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const Wrapper = styled.div`
  height: 100%;
  position: fixed;
  display: flex;
`;
