import styled from "styled-components";
import { keyframes } from "styled-components";

const LoadingSpinner = () => {
  return (
    <>
      <Spinner></Spinner>
      <ProcessingText>訂單處理中, 請稍侯</ProcessingText>
    </>
  );
};

export default LoadingSpinner;

const ProcessingText = styled.div`
  width: 100%;
  text-align: center;
`;

const AnimatedSpinner = keyframes`
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
`;

const Spinner = styled.div`
  display: inline-block;
  width: 80px;
  height: 80px;

  &:after {
    content: " ";
    display: block;
    width: 32px;
    height: 32px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid teal;
    border-color: teal transparent teal transparent;
    animation: ${AnimatedSpinner} 1.2s linear infinite;
  }
`;
