import React from "react";

const OrderContext = React.createContext({
  tableNumber: "",
  token: "",
  serialNumber: "",
  shopId: "",
});

export default OrderContext;
