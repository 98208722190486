import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ButtonGroup from "@mui/material/ButtonGroup";
import styled from "styled-components";
import ItemButton from "./ItemButton";
import Selection from "./Selection";

const CategoryTabs = (props) => {
  const menuItems = JSON.parse(localStorage.getItem("menuItems"));
  const [value, setValue] = useState(props.selectedCategoryPkey);
  let currentCategoryItems = getCategoryItems(value, menuItems);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    currentCategoryItems = getCategoryItems(newValue, menuItems);
  };

  const [optionIsShown, setOptionIsShown] = useState(false);
  const [itemKey, setItemKey] = useState(0);
  const selectedItem = currentCategoryItems[0]["Items"].find((item) => {
    return item.pkey === itemKey;
  });
  const showOptionHandler = (event) => {
    const pkey = event.currentTarget.attributes.pkey.value;
    setOptionIsShown(true);
    setItemKey(pkey);
  };
  const hideOptionHandler = (pkey) => {
    setOptionIsShown(false);
  };

  return (
    <>
      <Box>
        <Box
          sx={{
            flexGrow: 1,
            width: "100%",
            bgcolor: "background.paper",
          }}
        >
          <Tabs
            key={value}
            value={value}
            onChange={handleChange}
            textColor="primary"
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="scrollable force tabs example"
            sx={{
              [`& .${tabsClasses.scrollButtons}`]: {
                "&.Mui-disabled": { opacity: 0.3 },
              },
            }}
          >
            {menuItems.map((item) => (
              <Tab
                label={item.weborder_name1}
                {...a11yProps(item.pkey)}
                value={item.pkey}
                key={item.pkey}
              />
            ))}
          </Tabs>
        </Box>
        {menuItems.map((categories) => (
          <StyledTabPanel
            value={value}
            index={categories.pkey}
            key={categories.pkey}
          >
            <StyledButtonGroup
              orientation="vertical"
              color="primary"
              aria-label="vertical outlined primary button group"
            >
              {currentCategoryItems[0]["Items"].map((items) => (
                <ItemButton
                  items={items}
                  key={items.pkey}
                  onShowOption={showOptionHandler}
                />
              ))}
            </StyledButtonGroup>
          </StyledTabPanel>
        ))}
      </Box>
      {optionIsShown && (
        <Selection
          onClose={hideOptionHandler}
          itemKey={itemKey}
          selectedItem={selectedItem}
        />
      )}
    </>
  );
};

export default CategoryTabs;

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={"div"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

function getCategoryItems(categoryPkey, arrayCategory) {
  return arrayCategory.filter(function (obj) {
    return obj.pkey === categoryPkey;
  });
}

const StyledTabPanel = styled(TabPanel)`
  padding-top: 10px;
  height: 68vh;
  overflow: scroll;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.7);
`;

const StyledButtonGroup = styled(ButtonGroup)`
  display: flex;
  width: 100%;
`;
