import React from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const ItemButton = (props) => {
  const items = props.items;
  return (
    <Button
      onClick={props.onShowOption}
      sx={{ height: "70px", display: "flex", width: "100%" }}
      key={items.pkey}
      pkey={items.pkey}
    >
      <ButtonItemText pkey={items.pkey}>{items.weborder_name1}</ButtonItemText>
      <ButtonItemPrice pkey={items.pkey}>${items.price1}</ButtonItemPrice>
      <ButtonItemIcon pkey={items.pkey}>
        <AddCircleOutlineIcon />
      </ButtonItemIcon>
    </Button>
  );
};

export default ItemButton;

const ButtonItemText = styled.div`
  justify-content: left;
  text-align: left;
  flex: 4;
`;

const ButtonItemPrice = styled.div`
  flex: 2;
  text-align: right;
  justify-content: flex-end;
`;

const ButtonItemIcon = styled.div`
  flex: 1;
  text-align: right;
  justify-content: flex-end;
`;
