import React from "react";
import { useContext, useState } from "react";
import CartContext from "../../store/CartContext";
import styled from "styled-components";
import Modal from "../UI/Modal";
import Button from "@mui/material/Button";
import CancelIcon from '@mui/icons-material/Cancel';
import Options from "./Options";
import Specials from "./Specials";
import Divider from "@mui/material/Divider";
import AddItemForm from "./AddItemForm";

const Selection = (props) => {
  const cartCtx = useContext(CartContext);

  const currentItem = props.selectedItem;
  //const price = `$${currentItem.price1}`;
  const name = currentItem.weborder_name1;
  //const foodcode = currentItem.foodcode;
  let quantity = 1;

  const [itemPrice, setItemPrice] = useState(+currentItem.price1);
  const [specialsSet, setSpeicalsSet] = useState([]);
  const [optionsSet, setOptionsSet] = useState([]);
  // console.log("---S----");
  // console.log(optionsSet);
  // console.log(specialsSet);
  // console.log("----E---");
  const specialCode = currentItem.chgcode.slice(
    //extract last two set
    //currentItem.chgcode.length - 4,
    //currentItem.chgcode.length - 2
    0,
    2
  );
  const specialMax = 6;

  const addToCartHandler = () => {
    cartCtx.addItem({
      id: Date.now(),
      pkey: currentItem.pkey,
      name: name,
      foodcode: currentItem.foodcode,
      itemname: currentItem.itemname,
      itemlabel: currentItem.itemlabel,
      printer: currentItem.p,
      settype: currentItem.settype,
      setqty: currentItem.setqty,
      category: currentItem.category,
      quantity: quantity,
      price: +currentItem.price1 * quantity,
      specials: specialsSet,
      options: optionsSet,
      totalAmount: itemPrice,
    });
    props.onClose();
  };

  const updatePriceHandler = (newPrice) => {
    const p = +itemPrice;
    setItemPrice(p + newPrice);
  };

  const updateSpecialHandler = (specialsSet) => {
    setSpeicalsSet(specialsSet);
  };

  const updateOptionsHandler = (options) => {
    const updatedOptions = optionsSet.concat(options);

    setOptionsSet(updatedOptions);
  };

  return (
    <Modal onClose={props.onClose}>
      <Container>
        <Nav>
          <NavLeft>
            <NavLeftL>{name}</NavLeftL>
            <NavLeftR>{`$ ${itemPrice.toFixed(1)}`}</NavLeftR>
          </NavLeft>
          <NavRight>
            <Button onClick={props.onClose}>
              <CancelIcon fontSize="medium" />
            </Button>
          </NavRight>
        </Nav>

        {currentItem.gogoopt && (
          <OptionContainer>
            <Options
              optionCode={currentItem.gogoopt}
              optionCount={currentItem.gogocount}
              specialCode={specialCode}
              specialMax={specialMax}
              onUpdatePrice={updatePriceHandler}
              onUpdateSpecial={updateSpecialHandler}
              onUpdateOptions={updateOptionsHandler}
              selectedOptions={optionsSet}
              addCart={addToCartHandler}
            />
          </OptionContainer>
        )}
        {!currentItem.gogoopt && (
          <>
            <SpecialSection>
              <Divider>請選擇特別要求(最多{specialMax}項)</Divider>
              <Specials
                specialCode={specialCode}
                specialMax={specialMax}
                onUpdatePrice={updatePriceHandler}
                onUpdateSpecial={updateSpecialHandler}
              />
            </SpecialSection>
            <Bottom>
              <AddItemForm onAddToCart={addToCartHandler} />
            </Bottom>
          </>
        )}
      </Container>
    </Modal>
  );
};

export default Selection;

const SpecialSection = styled.div`
  width: 100%;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Nav = styled.div`
  width: 100%;
  height: 5vh;
  display: flex;
  //background-color: yellow;
`;

const OptionContainer = styled.div`
  width: 100%;
  height: 75vh;
  //overflow: scroll;
`;

const NavLeft = styled.div`
  height: 100%;
  font-weight: bold;
  font-size: 20px;
  flex: 2;
  //background-color: #9f9fe5;
  display: flex;
`;

const NavLeftL = styled.div`
  flex: 2;
  padding-top: 5%;
`;

const NavLeftR = styled.div`
  flex: 1;
  padding-top: 5%;
`;

const NavRight = styled.div`
  flex: 1;
  text-align: right;
`;

const Bottom = styled.div`
  width: 100%;
  height: 5vh;
  text-align: center;
  margin-top: auto;
  align-self: flex-end;
`;
