import { Facebook, Instagram } from "@mui/icons-material";
import styled from "styled-components";

const Footer = () => {
  return (
    <Container>
      <Left>
        <LogoContainer>
          <Image src="/img/logo_full.png" />
        </LogoContainer>
      </Left>
      <Center></Center>
      <Right>
        <SocialContainer>
          <SocialIcon color="3B5999">
            <Facebook sx={{ fontSize: 18 }} />
          </SocialIcon>
          <SocialIcon color="E4405F">
            <Instagram sx={{ fontSize: 18 }} />
          </SocialIcon>
        </SocialContainer>
      </Right>
    </Container>
  );
};

export default Footer;

const Container = styled.div`
  display: flex;
  position: fixed;
  bottom: 0;
  padding: 2px;
`;

const Left = styled.div`
  flex: 1;
`;
const Center = styled.div`
  flex: 1;
  padding: 0px;
`;
const LogoContainer = styled.div`
  display: flex;
  padding-top: 10px;
  padding-left: 10px;
  justify-content: center;
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
`;

const Right = styled.div`
  flex: 1;
  padding: 5px;
`;

const SocialContainer = styled.div`
  display: flex;
  flex-direction: flex-end;
  padding: 5px;
  justify-content: center;
`;
const SocialIcon = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: white;
  background-color: #${(props) => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  margin-right: 5px;
`;
