import React from "react";
import styled from "styled-components";
import { useContext, useState } from "react";
import OrderContext from "../../store/OrderContext";
import CartContext from "../../store/CartContext";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import HomeIcon from "@mui/icons-material/Home";
import Button from "@mui/material/Button";
import { indigo } from "@mui/material/colors";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const SubNavBar = (props) => {
  const ctx = useContext(OrderContext);
  const cartCtx = useContext(CartContext);
  const [open, setOpen] = useState(false);

  const homeButtonHandler = function (props) {
    if (numberOfCartItems > 0) setOpen(true);
    else {
      window.location.reload();
    }
  };

  const numberOfCartItems = cartCtx.items.reduce((curNumber, item) => {
    return curNumber + item.quantity;
  }, 0);

  const handleClose = () => {
    setOpen(false);
  };

  const handleHomeConfrim = () => {
    setOpen(false);
    window.location.reload();
  };

  return (
    <>
      <Container>
        <Left>
          <Button onClick={homeButtonHandler} sx={{ textAlign: "left" }}>
            <HomeIcon sx={{ color: indigo[500] }} />
          </Button>
        </Left>
        <Center>
          <TableNumber>枱號: {ctx.tableNumber}</TableNumber>
        </Center>
        <Right>
          <CartWrapper>
            <IconButton aria-label="cart" onClick={props.onShowCart}>
              <StyledBadge
                badgeContent={numberOfCartItems.toString()}
                color="secondary"
              >
                <ShoppingCartIcon />
              </StyledBadge>
            </IconButton>
          </CartWrapper>
        </Right>
      </Container>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">確定離開?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            注意! 離開將會清空已選項目！
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>取消</Button>
          <Button onClick={handleHomeConfrim} autoFocus>
            確定
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SubNavBar;

const Container = styled.div`
  display: flex;
  height: 50px;
  width: 99%;
  //padding-left: 5px;
  padding-right: 5px;
  background-color: rgba(255, 255, 255, 0.7);
`;

const Left = styled.div`
  flex: 1;
  text-align: left;
`;

const Center = styled.div`
  flex: 1;
  text-align: center;
`;

const TableNumber = styled.div`
  position: relative;
  top: 20%;
`;

const Right = styled.div`
  flex: 1;
  text-align: right;
  justify-content: flex-end;
`;

const CartWrapper = styled.div`
  text-align: right;
  justify-content: flex-end;
  position: relavie;
  top: 18%;
`;

const StyledBadge = styled(Badge)`
  //top: 4;
  //padding: "0 4px";
  margin-top: 5px;
`;
