import { useContext, useState } from "react";
import styled from "styled-components";
import OrderContext from "../store/OrderContext";
import StatusInfo from "./Menu/StatusInfo";
import { TouchApp, ShoppingCart } from "@mui/icons-material";
import Category from "./Menu/Category";
import SubNavBar from "./Menu/SubNavBar";
import Cart from "./Cart/Cart";
import CartProvider from "../store/CartProvider";
import OrderedItems from "./Enquiry/OrderedItems";

const ReadyForOrder = (props) => {
  const httpError = props.httpError;
  const tableNumber = props.tableNumber;
  const ctx = useContext(OrderContext);

  const [showMenu, setShowMenu] = useState(false);
  const [orderedIsShown, setOrderedIsShown] = useState(false);
  const [cartIsShown, setCartIsShown] = useState(false);

  const showCartHandler = () => {
    setCartIsShown(true);
  };
  const hideCartHandler = () => {
    setCartIsShown(false);
  };

  const showMenuHandler = () => {
    setShowMenu(true);
  };

  const showOrderedHandler = () => {
    setOrderedIsShown(true);
  };
  const hideOrderedHandler = () => {
    setOrderedIsShown(false);
  };

  if (!showMenu) {
    return (
      <Container>
        {cartIsShown && (
          <Cart onClose={hideCartHandler} tableNumber={tableNumber} />
        )}
        {orderedIsShown && <OrderedItems onClose={hideOrderedHandler} />}
        <StatusInfo
          isReadyForOrder={props.isReadyForOrder}
          tableNumber={ctx.tableNumber}
          httpError={httpError}
        />
        {props.isReadyForOrder && httpError === "" && (
          <>
            <StartOrder>
              <Button onClick={showMenuHandler}>
                <TouchApp style={{ fontSize: 20 }} /> 開始點餐
              </Button>
            </StartOrder>
            <OrderedEnquiry>
              <Button onClick={showOrderedHandler}>
                <ShoppingCart style={{ fontSize: 20 }} /> 查詢已點項目
              </Button>
            </OrderedEnquiry>
          </>
        )}
      </Container>
    );
  } else {
    return (
      <CartProvider>
        {cartIsShown && (
          <Cart onClose={hideCartHandler} tableNumber={tableNumber} />
        )}
        <SubNavBar onShowCart={showCartHandler} />
        <Category />
      </CartProvider>
    );
  }
};

export default ReadyForOrder;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  margin-top: 50px;
`;

const StartOrder = styled.div`
  flex: 1;
  cursor: pointer;
`;
const OrderedEnquiry = styled.div`
  margin-top: 5px;
  flex: 1;
  cursor: pointer;
`;
const Button = styled.button`
  width: 200px;
  padding: 15px;
  border: 2px solid teal;
  background-color: white;
  cursor: pointer;
  font-weight: 500;
  &:hover {
    background-color: #f8f4f4;
  }
`;
