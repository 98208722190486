import React from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";

const OptionContent = (props) => {
  const options = props.optionItems;
  const selectedOptions = props.selectedOptions;
  const onOptionClickHandler = props.onOptionClick;
  return (
    <Container>
      {options.map((obj, index) => (
        <StyledButton
          key={obj.pkey}
          variant={
            selectedOptions.find((e) => e.pkey === obj.pkey)
              ? "contained"
              : "outlined"
          }
          color={
            selectedOptions.find((e) => e.pkey === obj.pkey)
              ? "success"
              : "primary"
          }
          onClick={onOptionClickHandler.bind(
            null,
            obj.pkey,
            obj.weborder_name1,
            obj.optprice,
            obj.split,
            obj.foodcode,
            obj.itemlabel,
            obj.itemname,
            obj.p
          )}
          disabled={false}
          sx={{ margin: "2px" }}
        >
          <ButtonText>{obj.weborder_name1}</ButtonText>
          <ButtonPrice>
            {parseFloat(obj.optprice) > 0 && `$${parseFloat(obj.optprice)}`}
          </ButtonPrice>
        </StyledButton>
      ))}
    </Container>
  );
};

export default OptionContent;

const Container = styled.div`
  width: 100%;
  height: 58vh;
`;

const StyledButton = styled(Button)`
  width: 48%;
  height: 80px;
  display: flex;
  padding: 0px;
`;

const ButtonText = styled.div`
  flex: 3;
  text-align: left;
`;
const ButtonPrice = styled.div`
  flex: 1;
  text-align: right;
  color: red;
`;
